<template>
  <div class="full-width">
    <b-card>
      <operativeRemittances :operative-id="opInfo.operativedetails[0].ent_id" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import operativeRemittances from '@/components/operatives/OperativeRemit.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    operativeRemittances,
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
}
</script>

<style>

</style>
